/** @module shop */

import { addToCart } from './molecules/add-to-cart/add-to-cart.js';
import { starRating } from './molecules/ratings/star-rating.js';
import { checkoutButtonIndicator } from './atoms/checkout-button-indicator/checkout-button-indicator.js';

/**
 * Initializes shop functionalities.
 *
 * @return {Promise<void>}
 */
export async function shop() {
    addToCart();
    starRating();
    checkoutButtonIndicator();
}
