/** @module shop/add-to-cart */

/**
 * Initializes AJAX add-to-cart-functionality for WooCommerce.
 *
 * @return {Promise<void>}
 */
export async function addToCart() {
    const addToCartButtons = document.querySelectorAll('[data-ajax-add-to-cart]');

    if (addToCartButtons && addToCartButtons.length) {
        const { initAddToCart } = await import(/* webpackMode: "lazy" */ './inc/add-to-cart.async.js');

        initAddToCart(addToCartButtons);
    }
}
