/** @module application */

/**
 * Manages filtering items.
 *
 * @returns {Promise<void>} Resolves immediately if no filters need to be managed, or once the required script loaded.
 */
async function filters() {
    const filters = document.querySelectorAll('[data-filter]');

    if (filters.length) {
        const { initFilters } = await import(/* webpackMode: "lazy" */ './filters.async.js');

        initFilters(filters);
    }
}

async function searches() {
    const searches = document.querySelectorAll('[data-search]');

    if (searches.length) {
        const { initSearches } = await import(/* webpackMode: "lazy" */ './searches.async.js');

        initSearches(searches);
    }
}

export { filters, searches };
