/**
 * @module security
 */

import { toElements } from '../utils/to-elements.js';

function isExternalDomain(url) {
    let protocolRegex = /^(https?:)?\/\//;

    if (protocolRegex.test(url)) {
        let protocol = url.match(protocolRegex),
            domain = url.substr(protocol[0].length).split(/[/?]/)[0];

        return domain !== location.hostname;
    }

    return false;
}

function addRelAttributeNoreferrer(el) {
    let rel = el.getAttribute('rel');

    if (rel) {
        rel = rel.split(' ');
    } else {
        rel = [];
    }

    rel = rel.concat(['noreferrer']);
    rel = [...new Set(rel)]; // filter duplicates
    el.setAttribute('rel', rel.join(' '));
    el.dataset.sec = '1';
}

function secureExternalAnchors() {
    toElements(
        `a[target="_blank"]:not([data-sec="1"]),
        area[target="_blank"]:not([data-sec="1"]),
        form[target="_blank"]:not([data-sec="1"])`,
    ).forEach((el) => {
        let target = el.href || el.action;

        if (isExternalDomain(target)) {
            // do not add the rel attribute to all _blank-anchors,
            // as same-domain-anchors may get some performance boost by browsers from not adding these values
            addRelAttributeNoreferrer(el);
        }
    });
}

/**
 * Checks all anchors and forms with attribute <code>target</code> set to <code>'_blank'</code> and an attribute
 * <code>action</code> resp. <code>href</code> linking to a different domain whether their rel-attribute contains
 * <code>'noreferrer'</code> and sets it, if required. Checked anchors are marked with attribute <code>data-sec</code>
 * set to <code>'1'</code>. The check is performed when the script is loaded and whenever the readystate of the document
 * changes, so that all anchors are marked early and probably get some anchors added by other scripts.<br>
 * NOTE: Adding these values to *all* elements with attribute <code>target="_blank"</code> can have performance
 * impacts, as browser may optimize new tabs/windows opened for same-domain anchors, what is not the case when they were
 * opened without referrer.<br>
 * NOTE: rel="noreferrer" has the same effect as rel="noreferrer noopener".
 * @listens readystatechange
 * @see https://html.spec.whatwg.org/multipage/links.html#link-type-noreferrer
 * @see https://web.dev/external-anchors-use-rel-noopener/
 */
export function initSecuringExternalAnchors() {
    // secure anchors instantly
    secureExternalAnchors();

    // and whenever the readystate of the document changes to not miss anything
    document.addEventListener('readystatechange', secureExternalAnchors);
}
