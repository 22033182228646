/** @module popUp */

/**
 * Initializes pop-up functionalities. Elements intended to open a pop-up should have an attribute `data-pop-up-open`
 * set to the ID of the pop-up intended to open. Elements intended to close a pop-up should live inside the
 * `[data-pop-up]`-element and have an attribute `data-pop-up-close`.
 *
 * @param {object} [options={}] - Options for the pop-up.
 * @param {boolean} [options.blockScrolling=false] - Whether the site's scrolling is blocked while the pop-up is open.
 *
 * @return {Promise<void>} - Resolves immediately, if no element for opening pop-ups is on the page, or once
 * functionality is all set up.
 */
export async function popUp(options = {}) {
    if (document.querySelector('dialog[data-pop-up]')) {
        const { initPopUp } = await import(/* webpackMode: "lazy" */ './inc/popUp.async.js');

        initPopUp(options);
    }
}
