/** @module shop/star-rating */

/**
 * Initializes star-ratings.
 *
 * @return {Promise<void>}
 */
export async function starRating() {
    if (document.querySelectorAll('[data-rating]')) {
        const { initStarRating } = await import(/* webpackMode: "lazy" */ './inc/star-rating.async.js');

        initStarRating();
    }
}
