/** @module shop/checkout-button-indicator */

/**
 * Handles the loading-indicator on the checkout-button.
 *
 * @return {Promise<void>}
 */
export async function checkoutButtonIndicator() {
    const checkoutButton = document.querySelector('.woocommerce .checkout-button');

    if (checkoutButton) {
        const { initCheckoutButtonIndicator } = await import(
            /* webpackMode: "lazy" */ './inc/checkout-button-indicator.async.js'
        );

        initCheckoutButtonIndicator(checkoutButton);
    }
}
