/** @module application */

async function autoScrolling(options) {
    let { AutoScrolling } = await import(/* webpackMode: "lazy" */ './auto-scrolling.js');

    return AutoScrolling.singleton(options);
}

async function liveEvents(options = {}) {
    let { LiveEvents } = await import(/* webpackMode: "lazy" */ './live-events.js');

    return LiveEvents.singleton(options);
}

import { bugsnag } from './bugsnag.js';
import { clearAway } from './clear-away.js';
import { globalState } from './globalState.js';
import { serviceWorker } from './service-worker.js';
import { handleTabKey } from './handle-tab-key.js';

export { autoScrolling, bugsnag, clearAway, globalState, liveEvents, serviceWorker, handleTabKey };
