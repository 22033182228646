/** @module callout */

import $ from 'jquery';

/**
 * Ensures the parent alignment-wrapper is also hidden when closing a callout.
 */
export function callout() {
    $('[data-callout][data-closable]').on('closed.zf', (event) => {
        let callout = event.target,
            alignmentWrapper = callout.closest('[data-alignment-wrapper]');

        if (alignmentWrapper) {
            // TODO: foundation issue
            // Foundation triggers the close-event before the fadeOut()-animation is completed
            // -> Observe, when the callout is actually hidden, then hide the paren alignment-wrapper
            const observer = new MutationObserver((items) => {
                items.forEach(({ type, target, attributeName }) => {
                    if (type === 'attributes' && attributeName === 'style' && target.style.display === 'none') {
                        alignmentWrapper.hidden = true;
                        alignmentWrapper.style.display = 'none';
                    }
                });
            });

            observer.observe(event.target, { attributes: true });
        }
    });
}
