export async function shopMyAccountValidation(parsleyOptions) {
    let myAccountForm = document.querySelector('.woocommerce-MyAccount-content form');
    //let myAccountForm = document.querySelector('.woocommerce-MyAccount-content form:not(.woocommerce-EditAccountForm)');

    if (myAccountForm) {
        let { initShopMyAccountValidation } = await import(
            /* webpackMode: "lazy" */ './inc/shopMyAccountValidation.async.js'
        );

        initShopMyAccountValidation(parsleyOptions);
    }
}
