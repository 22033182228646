/** @module application */

import { liveEvents } from '../../../../assets/js/application/index.js';

/**
 * Manages clickability of card components.
 * Clickable card components are identified by class `.card` and attribute `data-clickable`.
 * Non-clickable card sub-components are identified by attribute `data-card-not-clickable`.
 * @see https://inclusive-components.design/cards/
 * @returns {Promise<void>}
 */
export async function cards() {
    const selector = '[data-card][data-clickable]',
        ignoreClicksTo = '[data-card-not-clickable]',
        linkSelector = '[data-card-link]',
        listenerOptions = { passive: true };

    let downTimestamp,
        downTarget,
        downCoords,
        upTimestamp,
        upTarget,
        upCoords,
        moveThreshold = 10;

    (await liveEvents())
        .addEventListener(
            selector,
            'mousedown',
            (event) => {
                downTimestamp = performance.now();
                downTarget = event.target.closest(selector);
                downCoords = {
                    x: event.clientX,
                    y: event.clientY,
                };
            },
            listenerOptions,
        )
        .addEventListener(
            selector,
            'mouseup',
            (event) => {
                if (event.target.closest(ignoreClicksTo)) {
                    return;
                }

                upTimestamp = performance.now();
                upTarget = event.target.closest(selector);
                upCoords = {
                    x: event.clientX,
                    y: event.clientY,
                };

                const clickableCard = upTarget,
                    link = clickableCard && clickableCard.querySelector(linkSelector);

                if (
                    upTimestamp - downTimestamp < 200 &&
                    downTarget === upTarget &&
                    (downTarget.matches('a') === false || downTarget.matches(linkSelector)) &&
                    Math.abs(downCoords.x - upCoords.x) <= moveThreshold &&
                    Math.abs(downCoords.y - upCoords.y) <= moveThreshold &&
                    Boolean(clickableCard) &&
                    Boolean(link) &&
                    upTarget !== link
                ) {
                    // simulate a click on the link
                    link.click();
                }
            },
            { ...listenerOptions, capture: true },
        );
}
